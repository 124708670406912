import { forwardRef } from 'react'
import { IPossibleLocations } from 'data-transfers/dto'
import { useField } from 'formik'

import { Autocomplete } from 'components/selects'
import {
  IAutocompleteProps,
  IForwardRefAutocompleteProps
} from 'components/selects/Autocomplete/types'

const CityAutocompleteField = forwardRef<
  IForwardRefAutocompleteProps,
  IAutocompleteProps<IPossibleLocations>
>(function CityAutocompleteField(
  { name, items, allowedCountry, ...props },
  ref
) {
  const [{ value }] = useField<IPossibleLocations | null>(name ?? '')
  return (
    <Autocomplete
      ref={ref}
      items={items.filter(
        (x) => !allowedCountry || x.country?.name == allowedCountry.name
      )}
      name={name}
      itemToString={(x) => x?.city ?? ''}
      {...props}
      selectedItem={value}
    />
  )
})

export { CityAutocompleteField }
