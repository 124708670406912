import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'

import { IBadgeProps } from 'components/AdvertsPage/types'
import { SwiperButton } from 'components/buttons'

import Subpage from '../Subpage'

import useSubpages from './useSubpages'

const Subpages = (props: IBadgeProps) => {
  const {
    subpages,
    isStart,
    isEnd,
    onUpdateSwiper,
    updateStartAndEnd,
    isMobile,
    t
  } = useSubpages(props)
  return (
    <div className={'mt-4 flex justify-end'}>
      {isMobile ? (
        <div className={'w-full px-2'}>
          <div className={'my-1 font-semibold'}>
            {t('badges.title', { ns: 'home_adverts' })}
          </div>
          <div className={'flex flex-wrap gap-x-2'}>
            {subpages.map(({ text, count, link, value }, index) => (
              <Subpage
                href={link}
                count={count}
                text={text}
                value={value}
                key={index}
                isMobile={isMobile}
              />
            ))}
          </div>
        </div>
      ) : (
        <Swiper
          className={'ml-0 w-full'}
          spaceBetween={20}
          slidesPerView={'auto'}
          onUpdate={onUpdateSwiper}
          onAfterInit={updateStartAndEnd}
          onSlideChange={updateStartAndEnd}
        >
          <SwiperButton
            className={clsx(
              'hidden border-0 border-white bg-white shadow-2xl shadow-white md:flex md:pr-1 md:pl-0',
              isStart && 'invisible opacity-0'
            )}
            buttonClassName={'border-white hover:border-white'}
            type={'prev'}
          />
          {subpages.map(({ text, count, link, value }, index) => (
            <SwiperSlide
              key={index}
              className={clsx('mr-2 !w-fit', !count && 'hidden')}
            >
              <Subpage href={link} count={count} text={text} value={value} />
            </SwiperSlide>
          ))}

          <SwiperButton
            className={clsx(
              'hidden bg-white shadow-2xl shadow-white md:flex md:pl-2 md:pr-0',
              isEnd && 'invisible opacity-0'
            )}
            buttonClassName={'border-white hover:border-white'}
            type={'next'}
          />
        </Swiper>
      )}
    </div>
  )
}

export default Subpages
