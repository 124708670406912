import { forwardRef, PropsWithChildren, useMemo } from 'react'

import { SkeletonCard } from 'components/skeleton/components'

import { ViewMoreButton } from './components'
import { ICardsLoader } from './types'

const CardsLoader = forwardRef<HTMLDivElement, PropsWithChildren<ICardsLoader>>(
  function CardsLoader(
    {
      children,
      isMore,
      onViewMore,
      isLoading,
      loadingPlaceholderCount,
      viewMoreComponent: ViewMore = ViewMoreButton
    },
    ref
  ) {
    const loadingPlaceholders = useMemo(() => {
      return Array.from(Array(loadingPlaceholderCount).keys())
    }, [loadingPlaceholderCount])

    return (
      <div ref={ref}>
        <div className="relative grid grid-cols-1 gap-x-6 gap-y-5 py-5 md:grid-cols-2 lg:grid-cols-3">
          {children}
          {loadingPlaceholders.map((num) => (
            <SkeletonCard key={num} className="h-[169px]" />
          ))}
        </div>

        {isMore && <ViewMore onClick={onViewMore} isLoading={isLoading} />}
      </div>
    )
  }
)

export default CardsLoader
