import { PropsWithChildren, useMemo } from 'react'
import clsx from 'clsx'

export interface ITitleProps extends PropsWithChildren {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
  customClasses?: string
  showDot?: boolean
}

const Title = ({
  children,
  variant = 'h1',
  className,
  customClasses,
  showDot = true
}: ITitleProps) => {
  const render = useMemo(() => {
    return {
      h1: (
        <h1
          className={
            customClasses ?? clsx('text-3xl font-bold sm:text-5xl', className)
          }
        >
          {children}
          {showDot && <i className="dot" />}
        </h1>
      ),
      h2: (
        <h2 className={customClasses ?? clsx('text-2xl  font-bold', className)}>
          {children}
          {showDot && <i className="dot" />}
        </h2>
      ),
      h3: (
        <h3
          className={customClasses ?? clsx('text-xl font-semibold', className)}
        >
          {children}
          {showDot && <i className="dot" />}
        </h3>
      ),
      h4: (
        <h4 className={customClasses ?? clsx('font-semibold', className)}>
          {children}
          {showDot && <i className="dot" />}
        </h4>
      ),
      h5: (
        <h5 className={customClasses ?? clsx('text-lg font-bold', className)}>
          {children}
          {showDot && <i className="dot" />}
        </h5>
      ),
      h6: (
        <h6 className={customClasses ?? clsx('text-sm font-bold', className)}>
          {children}
          {showDot && <i className="dot" />}
        </h6>
      )
    }
  }, [customClasses, className, children, showDot])

  return render[variant]
}

export default Title
