import clsx from 'clsx'

import styles from 'styles/MenuIcon.module.scss'

const IconMenu = ({
  isOpen,
  onClick,
  className
}: {
  isOpen: boolean | undefined
  onClick?: () => void
  className?: string
}) => (
  <button
    className={clsx(styles.menu, isOpen && styles.active, className)}
    style={{
      transform: 'scale(0.8)'
    }}
    onClick={onClick}
  >
    <span className={clsx(styles.menu_block)}>
      <span
        className={clsx(
          styles.menu_inner,
          'hover:bg-red-500 hover:before:bg-red-500 hover:after:bg-red-500'
        )}
      ></span>
    </span>
  </button>
)

export default IconMenu
