import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import { AdvertVisibilityType } from 'enums'

import useFilter from 'components/MatchFilters/useFilter'

import useAdvertsPublicInfinity from 'hooks/useAdvert/useAdvertsPublicInfinity'

import { getLocalisedLink } from 'utils'

import { IAdvertsPageProps } from './types'

const LIMIT = 9

const useAdvertPage = ({
  initialAdverts,
  offset,
  initialCities
}: IAdvertsPageProps) => {
  const router = useRouter()
  const { t } = useTranslation(['home_adverts'])
  const pageQuery = router.query.page as unknown as number
  const subpage = router.query.subpage as unknown as string
  const advertsQuery = router.query?.adverts
  const [page, setPage] = useState(pageQuery ?? 1)

  const { locale } = useRouter()
  const { saveFilters, initialFilter } = useFilter({ type: 'adverts' })

  const badges = useMemo(
    () => initialCities.filter((item) => !!item.cityvalue),
    [initialCities]
  )

  const subpageFilter = useMemo(
    () =>
      badges
        .map((item) => {
          return { city: item.cityvalue, country: 'Lithuania' }
        })
        .find((item) => item.city === subpage),
    [badges, subpage]
  )

  const {
    data: advertsPages,
    isFetchingNextPage,
    isLoading,
    hasNextPage: isMore,
    fetchNextPage
  } = useAdvertsPublicInfinity({
    payload: {
      languageCode: locale,
      offset,
      limit: LIMIT,
      statuses: [AdvertVisibilityType.Active],
      orderingColumn: 'dateCreated',
      order: 'desc',
      ...subpageFilter,
      ...initialFilter
    },
    options: {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      initialData: Object.keys(initialFilter).length
        ? undefined
        : initialAdverts
    }
  })

  const adverts = useMemo(
    () => (advertsPages?.pages.slice(0, page).map((x) => x.data) ?? []).flat(),
    [advertsPages?.pages, page]
  )

  const total = useMemo(
    () => advertsPages?.pages[0]?.pagination?.total ?? 0,
    [advertsPages]
  )

  const pagesLoaded = useMemo(
    () => advertsPages?.pages.length ?? 0,
    [advertsPages]
  )

  const isPreLastPage = useMemo(() => {
    const totalPages = Math.ceil(total / LIMIT)
    const currentPage = advertsPages?.pageParams.length ?? 0

    return totalPages - currentPage === 1
  }, [advertsPages?.pageParams.length, total])

  const loadingPlaceholderCount = useMemo(() => {
    if (!isFetchingNextPage) {
      return 0
    }
    let countLastItems = total % LIMIT
    if (countLastItems === 0) {
      countLastItems = LIMIT
    }

    return isPreLastPage ? countLastItems : LIMIT
  }, [isFetchingNextPage, isPreLastPage, total])

  const onViewMore = useCallback(() => {
    saveFilters(initialFilter, page)
    if (pagesLoaded <= page) {
      fetchNextPage()
    }
    setPage((prev) => prev + 1)
  }, [fetchNextPage, initialFilter, page, pagesLoaded, saveFilters])

  const path = useMemo(() => getLocalisedLink('adverts', locale), [locale])

  const href = useMemo(() => {
    const baseURL = `${path}${subpage ? subpage : ''}?page=${
      pageQuery ? +pageQuery + 1 : 2
    }`

    if (advertsQuery) {
      return baseURL + `&adverts=${advertsQuery}`
    }

    return baseURL
  }, [advertsQuery, pageQuery, path, subpage])

  return {
    t,
    adverts,
    badges,
    isLoading,
    saveFilters,
    loadingPlaceholderCount,
    isFetchingNextPage,
    isMore,
    onViewMore,
    href,
    locale: router.locale
  }
}

export default useAdvertPage
