import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import Spinner from 'components/Spinner'
import { IconArrow } from 'icons'

import { IViewMoreButton } from './types'

const ViewMoreButton = ({ className, isLoading, onClick }: IViewMoreButton) => {
  const { t } = useTranslation(['actions'])
  return (
    <div className="fade-in flex w-full items-center justify-end">
      <button
        disabled={isLoading}
        className={clsx(
          'animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600',
          className
        )}
        onClick={onClick}
      >
        <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
          {t('viewMore', { ns: '' })}
        </span>
        {isLoading ? (
          <Spinner
            isVisible
            size={22}
            className="text-red"
            circleClasses="bg-gradient-to-r from-red-550"
          />
        ) : (
          <IconArrow />
        )}
      </button>
    </div>
  )
}

export default ViewMoreButton
