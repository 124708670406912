import {
  ICoordinatesDto,
  ILocation,
  IMapItemInfo,
  IPossibleLocation
} from 'data-transfers/dto'
import {
  IGetMapItemInfo,
  ILocationFilter,
  IShortAdvertFilter
} from 'data-transfers/requests'

import { authClient, publicClient } from 'utils'

export const getCandidatesCoordinates = async (params: IShortAdvertFilter) => {
  const { data } = await publicClient.get<ICoordinatesDto[][]>(
    '/maps/candidates',
    { params }
  )

  return data
}
export const getAdvertsCoordinates = async (params: IShortAdvertFilter) => {
  const { data } = await publicClient.get<ICoordinatesDto[][]>(
    '/maps/adverts',
    { params }
  )

  return data
}
export const getCandidatesInfo = async (
  params: Omit<IGetMapItemInfo, 'variant'>
) => {
  const { data } = await publicClient.get<IMapItemInfo[]>(
    '/maps/candidates/info',
    { params }
  )

  return data
}

export const getAdvertsInfo = async (
  params: Omit<IGetMapItemInfo, 'variant'>
) => {
  const { data } = await publicClient.get<IMapItemInfo[]>(
    '/maps/adverts/info',
    { params }
  )

  return data
}

export const getCandidatesPossibleLocations = async () => {
  const { data } = await publicClient.get<ILocation[]>(
    '/maps/candidates/filters'
  )

  return data
}
export const getAdvertsPossibleLocations = async (
  params: IPossibleLocation
) => {
  const { data } = await publicClient.get<ILocation[]>(
    '/maps/adverts/filters',
    { params }
  )

  return data
}

export const getLocations = async (payload: ILocationFilter) => {
  const { data } = await authClient.get<ILocation[]>('/maps/cities', {
    params: payload
  })

  return data
}
