import AdvertCard from 'components/AdvertCard'
import IAdverts from 'components/AdvertsPage/types'
import CardsLoader from 'components/CardsLoader'

const Adverts = ({ adverts, ...rest }: IAdverts) => (
  <CardsLoader {...rest}>
    {adverts.map((ad) => (
      <AdvertCard key={ad.id} ad={ad} className=" !border-black" />
    ))}
  </CardsLoader>
)

export default Adverts
