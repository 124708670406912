import { useTranslation } from 'next-i18next'

import NoRecords from 'components/NoRecords'
import Spinner from 'components/Spinner'

import { ILoadingWrapperProps } from './types'

const LoadingWrapper = <TData,>({
  children,
  isLoading,
  data,
  hasError,
  checkEmpty,
  emptyText
}: ILoadingWrapperProps<TData>) => {
  const { t } = useTranslation(['actions'])

  if (isLoading) {
    return <Spinner isVisible />
  }

  if (hasError) {
    return (
      <p className="text-center text-2xl font-medium">
        {t('general.couldNotLoadData', { ns: 'actions' })}
      </p>
    )
  }

  if (
    !data ||
    (checkEmpty &&
      ((Array.isArray(data) && data.length === 0) ||
        (typeof data === 'string' && data.length === 0)))
  ) {
    return emptyText === null ? null : <NoRecords text={emptyText} />
  }

  if (typeof children === 'function') {
    return children(data as NonNullable<TData>)
  }

  return <>{children}</>
}

export default LoadingWrapper
