import { useQuery } from '@tanstack/react-query'
import { ICoordinatesDto } from 'data-transfers/dto'
import { IGetMapData } from 'data-transfers/requests'

import { IUseQueryHook } from 'hooks/types'

import { mapKeys } from './keys'
import { getAdvertsCoordinates, getCandidatesCoordinates } from './map.service'

const useCoordinates = ({
  payload = { variant: 'adverts' },
  options
}: IUseQueryHook<IGetMapData, ICoordinatesDto[][]>) => {
  return useQuery(
    mapKeys.candidates(payload),
    async () => {
      const { variant, ...params } = payload

      return variant === 'adverts'
        ? await getAdvertsCoordinates(params)
        : await getCandidatesCoordinates(params)
    },
    { refetchOnWindowFocus: false, ...options }
  )
}

export default useCoordinates
