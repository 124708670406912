import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'throttle-debounce'

import { IForwardRefAutocompleteProps } from 'components/selects/Autocomplete/types'

import { useCandidatesLocations } from 'hooks/useMap'
import useMediaQuery from 'hooks/useMediaQuery'

import { maxWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

import { IAdvertFilters } from './types'

export const salaryOptions = [
  { label: 'anySalary', value: 0 },
  { label: 'fromEuro', value: 1000 },
  { label: 'fromEuro', value: 1500 },
  { label: 'fromEuro', value: 2000 },
  { label: 'fromEuro', value: 2500 },
  { label: 'fromEuro', value: 3000 },
  { label: 'fromEuro', value: 3500 },
  { label: 'fromEuro', value: 5000 }
]

const useJobAdsFilterForm = () => {
  const isMobile = useMediaQuery(maxWidth(RESPONSIVE.sm))
  const [isFilterBarOpen, setIsFilterBarOpen] = useState(false)
  const { t } = useTranslation(['inputs', 'actions', 'zod', 'filters'])
  const { locale } = useRouter()
  const {
    values,
    setFieldValue,
    submitForm: submit
  } = useFormikContext<IAdvertFilters>()
  const { data: locations } = useCandidatesLocations({
    payload: {
      variant: 'adverts',
      showAllLocations: true,
      locale
    },
    options: { refetchOnWindowFocus: false, keepPreviousData: true }
  })

  const allowedCountries = useMemo(
    () => locations?.map((location) => location.country!.name),
    [locations]
  )

  const ref = useRef<IForwardRefAutocompleteProps | null>(null)

  const debouncedChangeHandler = useMemo(
    () => (time: number) => debounce(time, submit),
    [submit]
  )

  const filtersCount = useMemo(() => {
    const { country, city, skills = [], salaryFrom, keywords } = values

    return (
      [country, city, salaryFrom, keywords && keywords.length > 1].filter(
        Boolean
      ).length + skills.length
    )
  }, [values])

  return {
    isMobile,
    values,
    setFieldValue,
    setIsFilterBarOpen,
    locations,
    isFilterBarOpen,
    t,
    submit,
    ref,
    allowedCountries,
    debouncedChangeHandler,
    filtersCount
  }
}

export default useJobAdsFilterForm
