import { useMemo } from 'react'
import clsx from 'clsx'
import { Form } from 'formik'

import { Button, ButtonIcon } from 'components/buttons'
import Input from 'components/Fields/Input'
import MenuIcon from 'components/MenuIcon'
import Modal from 'components/modals/Modal'
import {
  Autocomplete,
  CountryAutocomplete,
  SkillsSelect
} from 'components/selects'
import { IconFilter } from 'icons'

import { CityAutocompleteField } from '../MapFilterForm/CityAutocompleteField'

import useJobAdsFilterForm, { salaryOptions } from './useJobAdsFilterForm'

const JobAdsFilterFormContent = () => {
  const {
    isMobile,
    filtersCount,
    allowedCountries,
    debouncedChangeHandler,
    values,
    t,
    ref,
    submit,
    setFieldValue,
    isFilterBarOpen,
    setIsFilterBarOpen,
    locations
  } = useJobAdsFilterForm()

  const skillsSelect = useMemo(
    () => (
      <SkillsSelect
        name={'skills'}
        placeholder={`- ${t('skillsMultiple.shortLabel', {
          ns: 'inputs'
        })} -`}
        tooltip={
          values.skills?.length >= 21
            ? t('skillsMultiple.only21Skills', {
                ns: 'inputs'
              })
            : null
        }
        onChangeValue={!(isMobile && isFilterBarOpen) ? submit : undefined}
      />
    ),
    [isFilterBarOpen, isMobile, submit, t, values.skills?.length]
  )

  const countryAutocomplete = useMemo(
    () => (
      <CountryAutocomplete
        onSelect={(value) => {
          setFieldValue('country', value)

          setFieldValue('city', null)
          ref.current?.clear()
          !isMobile && submit()
        }}
        selectedItem={values.country}
        placeholder={`- ${t('country.label', {
          ns: 'inputs'
        })} -`}
        name={'country'}
        className={'flex-1'}
        isClearable
        allowedCountries={allowedCountries}
      />
    ),
    [allowedCountries, isMobile, ref, setFieldValue, submit, t, values.country]
  )

  const cityAutocomplete = useMemo(
    () => (
      <CityAutocompleteField
        onSelect={(value) => {
          setFieldValue('city', value)

          if (value) {
            setFieldValue('country', value?.country)
          }
          !isMobile && submit()
        }}
        allowedCountry={values.country}
        ref={ref}
        items={locations ?? []}
        placeholder={`- ${t('city.label', {
          ns: 'inputs'
        })} -`}
        name={'city'}
        className={'flex-1'}
        isClearable
      />
    ),
    [isMobile, locations, ref, setFieldValue, submit, t, values.country]
  )

  const salaryAutocomplete = useMemo(
    () => (
      <Autocomplete
        name={'salaryFrom'}
        placeholder={`- ${t(`salaryFrom.filterLabel`, {
          ns: 'inputs'
        })} -`}
        items={salaryOptions}
        onSelect={(salary) => {
          setFieldValue('salaryFrom', salary?.value)
          !isMobile && submit()
        }}
        itemToString={(x) =>
          x?.label
            ? t(`salaryFrom.options.${x?.label}` ?? '', {
                ns: 'inputs',
                salary: x?.value
              })
            : ''
        }
        isClearable
      />
    ),
    [isMobile, setFieldValue, submit, t]
  )

  const keywordsInput = useMemo(
    () => (
      <Input
        name={'keywords'}
        placeholder={`- ${t(`keyword.label`, { ns: 'inputs' })} -`}
        type="text"
        inputClassName={'border-gray-300 focus:border-gray-300'}
        labelClassName={'absolute'}
        onKeyDown={!isMobile ? debouncedChangeHandler(1000) : undefined}
      />
    ),
    [debouncedChangeHandler, isMobile, t]
  )

  return (
    <Form>
      {isMobile ? (
        <>
          <div className={'flex flex-nowrap items-center px-2'}>
            <div className={'w-full'}>{skillsSelect}</div>

            <ButtonIcon
              rounded
              className={clsx(
                'relative pb-0 pt-3 pl-1 pr-0.5',
                isFilterBarOpen && 'opacity-0'
              )}
              onClick={() => setIsFilterBarOpen(true)}
            >
              <IconFilter width={40} />
              {filtersCount > 0 && (
                <div
                  className={
                    'absolute top-1 right-1 w-[18px] rounded-full border-2 border-red-500 bg-red-500 text-center text-[10px] text-white'
                  }
                >
                  {filtersCount}
                </div>
              )}
            </ButtonIcon>
          </div>

          <Modal isOpen={isFilterBarOpen}>
            <div
              className={
                'right-0 flex h-[100vh] w-[100vw] flex-col justify-between bg-[#D8D8D826] p-6 pb-10'
              }
            >
              <div>
                <div className={'mb-8 flex justify-between'}>
                  <h2 className="my-2 text-2xl font-bold">
                    {t('filters', { ns: 'filters' })}
                  </h2>
                  <MenuIcon
                    isOpen={true}
                    onClick={() => setIsFilterBarOpen(false)}
                  />
                </div>

                <div className={'flex flex-col gap-6'}>
                  {keywordsInput}
                  {salaryAutocomplete}
                  {countryAutocomplete}
                  {cityAutocomplete}
                  {skillsSelect}
                </div>
              </div>

              <div className={'flex justify-between'}>
                <Button
                  type={'reset'}
                  variant={'secondary'}
                  className={'bg-[#e0e0e0]'}
                  onClick={() => {
                    setFieldValue('country', null)
                    setFieldValue('city', null)
                    setFieldValue('skills', [])
                    setFieldValue('salaryFrom', [])
                    setFieldValue('keywords', '')
                    setIsFilterBarOpen(false)
                    submit()
                  }}
                >
                  {t('clearAll', { ns: 'actions' })}
                </Button>
                <Button
                  type={'submit'}
                  onClick={() => {
                    submit()
                    setIsFilterBarOpen(false)
                  }}
                >
                  {t('apply2', { ns: 'actions' })}
                </Button>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div
          className={clsx(
            'relative z-50 flex w-full flex-col flex-wrap items-center justify-between gap-4 py-3 lg:flex-row',
            'before:absolute before:top-0 before:ml-[calc((100%_-_101vw)/2)]  before:h-full before:w-[100vw] before:bg-gray-50'
          )}
        >
          <div className={'flex w-full flex-col gap-4 lg:flex-1'}>
            {keywordsInput}
            {salaryAutocomplete}
          </div>
          <div className={'flex w-full flex-col gap-4 lg:flex-1'}>
            <div className={'flex flex-col justify-between gap-4 md:flex-row'}>
              {countryAutocomplete}
              {cityAutocomplete}
            </div>
            {skillsSelect}
          </div>
        </div>
      )}
    </Form>
  )
}

export default JobAdsFilterFormContent
