import { useTranslation } from 'next-i18next'

import { INoRecordsProps } from './types'

const NoRecords = ({ text, children }: INoRecordsProps) => {
  const { t } = useTranslation(['crm'])
  return (
    <div className="w-full">
      <p className="text-center text-lg text-gray-400">
        {text === undefined ? t('noRecords', { ns: 'crm' }) : text}
      </p>
      {children}
    </div>
  )
}

export default NoRecords
