import { useMemo } from 'react'
import { AdvertVisibilityType } from 'enums'

import useAdvertTitle from 'hooks/useAdvertTitle'

import { getLocalisedLink } from 'utils'

import { useAdvertTranslation } from '../Advert'

import { AdvertLinkResolver, IAdvertCardProps } from './types'

export const advertLinkResolver: AdvertLinkResolver = (advert, locale) => {
  return advert.visibilityType === AdvertVisibilityType.Draft
    ? `/ad/edit/${advert.id}`
    : `${getLocalisedLink('advert', locale)}${advert.slug}`
}

const useAdvertCard = ({ ad }: IAdvertCardProps) => {
  const { t, lang } = useAdvertTranslation({
    ns: ['inputs', 'crm'],
    translation: ad.translation
  })

  const { title } = useAdvertTitle({
    ad,
    brandName: ad?.organisation?.brandName,
    lang
  })

  const competency = useMemo(() => {
    if (!ad?.competencies.length) {
      return null
    }

    const [skill] = ad.competencies.sort((a, b) => {
      if (a.priority > b.priority) return 1
      if (a.priority < b.priority) return -1

      if (a.yearsOfExperience > b.yearsOfExperience) return -1
      if (a.yearsOfExperience < b.yearsOfExperience) return 1

      return 0
    })
    return skill ?? null
  }, [ad.competencies])

  return {
    t,
    title,
    competency
  }
}

export default useAdvertCard
