import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { IPercentageViewProps } from './types'

const PercentageView = ({
  percentage,
  className = 'flex flex-col'
}: IPercentageViewProps) => {
  const { t } = useTranslation(['data'])

  if (percentage == null) {
    return null
  }

  return (
    <div className={clsx('ml-2 text-center text-gray-400', className)}>
      <span className="block font-medium">{percentage}%</span>
      <span className="text-xs">{t('match', { ns: 'data' })}</span>
    </div>
  )
}

export default PercentageView
