import clsx from 'clsx'

import JobAdsFilterForm from 'components/Forms/JobAdsFilterForm'
import LoadingWrapper from 'components/LoadingWrapper'

import { ViewMoreLink } from '../CardsLoader/components'

import Adverts from './components/Adverts'
import Subpages from './components/Subpages'
import { IAdvertsPageProps } from './types'
import useAdvertPage from './useAdvertPage'

const AdvertsPage = (props: IAdvertsPageProps) => {
  const {
    t,
    adverts,
    badges,
    isLoading,
    loadingPlaceholderCount,
    isFetchingNextPage,
    isMore,
    onViewMore,
    saveFilters,
    href,
    locale
  } = useAdvertPage(props)

  return (
    <>
      <JobAdsFilterForm saveFilters={saveFilters} />

      {locale === 'lt' && <Subpages cities={badges} />}

      <div className={clsx('mb-10', locale === 'en' && 'mt-2')}>
        <LoadingWrapper
          data={adverts}
          checkEmpty
          isLoading={isLoading}
          emptyText={t('noResults')}
        >
          <Adverts
            adverts={adverts}
            isMore={isMore}
            onViewMore={onViewMore}
            loadingPlaceholderCount={loadingPlaceholderCount}
            isLoading={isFetchingNextPage}
            viewMoreComponent={() => (
              <ViewMoreLink
                onClick={onViewMore}
                isLoading={isFetchingNextPage}
                href={href}
              />
            )}
          />
        </LoadingWrapper>
      </div>
    </>
  )
}

export default AdvertsPage
