import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { IMatchFilters } from 'data-transfers/requests'

import { IAdvertFilters } from 'components/Forms/JobAdsFilterForm/types'

import { getLocalisedLink, parseURLObject, stringifyURLObject } from 'utils'
import { IMatchTypes } from 'constants/match'

const useFilter = ({ type }: { type: IMatchTypes }) => {
  const router = useRouter()
  const subpageQuery = router.query?.subpage
  const initialFilter = useMemo(() => {
    const allFilters = router.query[type]
    const filters = parseURLObject<IMatchFilters>(allFilters)

    return filters ?? {}
  }, [router.query, type])

  const path = useMemo(
    () => getLocalisedLink('adverts', router.locale),
    [router.locale]
  )

  const saveFilters = useCallback(
    (filters: IMatchFilters | IAdvertFilters, page?: number) => {
      let pathname = router.pathname
      let routerQuery = router.query

      if (subpageQuery && subpageQuery !== filters.city) {
        pathname = path
        routerQuery = {}
      }

      const queryFilters = stringifyURLObject(filters)
      const query = JSON.parse(
        JSON.stringify({
          ...routerQuery,
          page,
          [type]: queryFilters
        })
      )

      router.push(
        {
          pathname,
          query
        },
        undefined,
        { shallow: true }
      )
    },
    [path, router, subpageQuery, type]
  )

  return { initialFilter, saveFilters }
}
export default useFilter
