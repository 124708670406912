import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { IPossibleLocations } from 'data-transfers/dto'
import { MapVariant } from 'data-transfers/requests'

import { IUseQueryHookWithPayload } from 'hooks/types'

import countries from 'data/countries'
import { ILocale } from 'types/app'

import { mapKeys } from './keys'
import {
  getAdvertsPossibleLocations,
  getCandidatesPossibleLocations
} from './map.service'

const useCandidatesLocations = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  { variant: MapVariant; showAllLocations?: boolean; locale?: ILocale },
  IPossibleLocations[]
>) => {
  const { locale } = useRouter()
  return useQuery(
    mapKeys.filters(payload),
    async () => {
      const { variant, ...params } = payload
      const data =
        variant === 'candidates'
          ? await getCandidatesPossibleLocations()
          : await getAdvertsPossibleLocations(params)

      return data.map(({ country: countryName, city }) => {
        const { translate, ...country } = countries.find(
          (x) => x.name === countryName
        )!
        return {
          city,
          country: {
            ...country,
            label: translate[locale] ?? country.name
          }
        }
      })
    },
    { refetchOnWindowFocus: true, ...options }
  )
}

export default useCandidatesLocations
