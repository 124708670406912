import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { ISkill } from 'data-transfers/dto'
import { IMatchFilters } from 'data-transfers/requests'
import { Formik } from 'formik'

import { useCandidatesLocations } from 'hooks/useMap'
import { useSkills } from 'hooks/useSkills'

import countries from 'data/countries'
import { parseURLObject } from 'utils'

import JobAdsFilterFormContent from './Form'
import { IAdvertFilters, JobAdsFilterFormProps } from './types'

const JobAdsFilterForm = ({ saveFilters }: JobAdsFilterFormProps) => {
  const { locale, query } = useRouter()
  const advertsQuery = query?.adverts
  const subpageQuery = query?.subpage

  const { data: locations } = useCandidatesLocations({
    payload: {
      variant: 'adverts',
      showAllLocations: true,
      locale
    },
    options: { refetchOnWindowFocus: false, keepPreviousData: true }
  })

  const { data: skillsOptions } = useSkills({
    languageCode: locale
  })

  const initialValues = useMemo(() => {
    const advertFilters = parseURLObject(advertsQuery) ?? {}
    let skills: ISkill[] = []

    if (advertFilters?.country || subpageQuery) {
      const filterCountry = subpageQuery ? 'Lithuania' : advertFilters?.country
      const country = countries.find((item) => item.name === filterCountry)

      if (country) {
        advertFilters.country = { ...country, label: country.translate[locale] }
      }
    }

    if (advertFilters?.city || subpageQuery) {
      const filterCity = subpageQuery ?? advertFilters?.city
      const location = locations?.find((item) => item.city === filterCity)

      if (location || advertFilters) {
        advertFilters.city = { ...location }
      }
    }

    if (advertFilters?.skillIds) {
      const ids = advertFilters?.skillIds as string[]
      const items = ids
        .map((id) => skillsOptions?.find((item) => item.id === id))
        .filter(Boolean)

      skills = items?.map((item) => {
        return { skillId: item?.id, skill: item?.value }
      }) as unknown as ISkill[]
    }

    return {
      country: advertFilters?.country ?? null,
      city: advertFilters?.city ?? null,
      skills: skills ?? [],
      salaryFrom: advertFilters?.salaryFromFilter ?? null,
      keywords: advertFilters?.keywords ?? ''
    } as IAdvertFilters
  }, [advertsQuery, locale, locations, skillsOptions, subpageQuery])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values: IAdvertFilters) => {
        const { country, city, skills, salaryFrom, keywords } = values

        const filters = {
          country: country?.name,
          city: city?.city,
          skillIds: skills.map((skill) => skill.skillId),
          salaryFromFilter: salaryFrom,
          keywords
        }
        saveFilters(filters as IMatchFilters)
      }}
    >
      <JobAdsFilterFormContent />
    </Formik>
  )
}

export default JobAdsFilterForm
