import { useTranslation } from 'next-i18next'
import { ChangeEvent, KeyboardEvent } from 'react'
import clsx from 'clsx'
import { ErrorMessage, Field } from 'formik'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import { getZodMessage } from 'utils/validation.util'

export const InputStyle = {
  fieldSimple:
    'focus:shadow-outline block h-full w-full appearance-none border-none bg-transparent py-2 px-4 text-gray-700 transition-all focus:shadow-transparent focus:outline-none',
  info: 'absolute text-xs tracking-wider antialiased pt-1',
  helper: 'fade-in text-gray-400 text-xs tracking-wider antialiased',
  error:
    'fade-in font-medium text-error-400 text-xs tracking-wider antialiased',
  input:
    'block h-10 w-full rounded-sm border-0 border-b-2 border-gray-200 focus:border-gray-400 text-gray-700 transition-colors focus:border-black',
  label: 'block pt-4 pb-2 text-lg font-semibold tracking-wide text-gray-900',
  fieldWrap: 'relative',
  required: 'ml-1 font-bold text-red-550'
}

export type InputProps = {
  label?: string | JSX.Element
  name: string
  value?: string
  placeholder?: string
  type?: string
  helperText?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  labelStyle?: string
  disabled?: boolean
  maxlength?: number
  required?: boolean
  as?: string
  min?: number
  showMarker?: boolean
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onPaste?: (e: ClipboardEvent) => void
}

const Input = ({
  label,
  name,
  placeholder,
  type = 'text',
  disabled,
  maxlength,
  required,
  className,
  inputClassName,
  labelClassName,
  helperText,
  onKeyPress,
  onKeyDown,
  onPaste,
  as,
  min,
  showMarker,
  ...props
}: InputProps) => {
  const { t } = useTranslation(['zod'])
  z.setErrorMap(makeZodI18nMap(t))

  return label === '' ? (
    <Field
      className={clsx(InputStyle.fieldSimple, className)}
      name={name}
      placeholder={placeholder}
      type={type}
      min={min}
      {...props}
    />
  ) : (
    <div className={clsx(InputStyle.fieldWrap, className)}>
      <label className={clsx(InputStyle.label, labelClassName)} htmlFor={name}>
        {label}
        {required && <span className={InputStyle.required}>*</span>}
      </label>
      <div className={'flex items-center'}>
        {showMarker && (
          <div
            className={
              'mr-1 h-2 w-2 rounded-full border-[1px] border-black bg-black'
            }
          ></div>
        )}
        <Field
          min={min}
          as={as}
          className={clsx(InputStyle.input, inputClassName)}
          name={name}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          type={type}
          disabled={disabled}
          maxLength={maxlength}
          required={required}
          {...props}
        />
      </div>
      <div className={InputStyle.info}>
        <div className={InputStyle.helper}>{helperText}</div>
        <ErrorMessage
          name={name}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="fade-in text-medium text-error-400">
                {getZodMessage(msg, t(msg, { ns: 'zod' }))}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default Input
