import {
  IGetMapData,
  IGetMapItemInfo,
  MapVariant
} from 'data-transfers/requests'

import { ILocale } from 'types/app'

export const mapKeys = {
  all: ['map-data'] as const,
  filters: (payload: { variant: MapVariant; locale?: ILocale }) =>
    [...mapKeys.all, payload, 'filters'] as const,
  mapItemsInfo: (payload: IGetMapItemInfo) =>
    [...mapKeys.all, payload, 'map-items-info'] as const,
  candidates: (payload: IGetMapData) =>
    [...mapKeys.all, payload, 'candidates-coordinates'] as const
}
