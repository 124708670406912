import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { Button } from 'components/buttons'
import Title from 'components/Title'
import { IconArrow, IconCircleCheck, IconCircleMinus } from 'icons'

import { PricingBulletsSliceDefaultItem } from 'types/prismic'
import { formatMoney } from 'utils'

import { IPricingPlanProps } from './types'

const PricingBullet = ({
  title,
  active,
  comingSoon
}: PricingBulletsSliceDefaultItem) => {
  const { t } = useTranslation('pricing_plans')
  return (
    <div className="flex items-center gap-5">
      <div>{active ? <IconCircleCheck /> : <IconCircleMinus />}</div>
      <div>
        {title}
        {comingSoon && <b> {t('comingSoon', { ns: 'pricing_plans' })}</b>}
      </div>
    </div>
  )
}

const PricingPlan = ({
  time,
  active,
  price,
  onClick,
  planInfo,
  startNowButton = true,
  tiny,
  showSelector,
  className
}: IPricingPlanProps) => {
  const { t } = useTranslation('pricing_plans')
  const { locale } = useRouter()
  return (
    <div
      className={clsx(
        'group flex flex-col border-2 p-6 transition-colors sm:mx-0',
        !tiny && 'gap-y-5',
        active ? 'border-black' : 'border-gray-400 ',
        showSelector && 'hover:border-black',
        className
      )}
      onClick={onClick}
    >
      {!time && planInfo?.primary.note && (
        <div className="bg-red-550 p-2 font-semibold uppercase text-white">
          {planInfo?.primary.note}
        </div>
      )}

      {time && (
        <>
          <div className="flex items-start justify-between">
            <Title className="font-semibold uppercase md:text-3xl" variant="h2">
              {planInfo?.primary.title}
            </Title>
            {showSelector && (
              <div
                className={clsx(
                  'relative flex h-8 w-8 items-center justify-center rounded-full border-4 border-black bg-white transition-all',
                  'group-hover:border-red-550 group-active:border-red-550',
                  active && 'border-red-550'
                )}
              >
                <div
                  className={clsx(
                    'h-4 w-4 rounded-full bg-red-550/0 transition-all',
                    'group-hover:bg-red-550/60 group-active:bg-red-550/80',
                    active && 'bg-red-550/100'
                  )}
                ></div>
              </div>
            )}
          </div>
          <div className="text-xl font-semibold">
            {!!planInfo?.primary?.[`${time}OldPrice`] && (
              <s className="font-normal">
                {' '}
                {formatMoney({
                  amount: Number(planInfo?.primary?.[`${time}OldPrice`]),
                  locale
                })}
              </s>
            )}{' '}
            {formatMoney({ amount: price, locale })}
            <span className="text-sm font-bold">/ {t(`${time}Price`)}</span>
          </div>
          <div className="min-h-[4rem] text-sm text-gray-600">
            {planInfo?.primary.description}
          </div>
        </>
      )}

      {startNowButton && (
        <div>
          <Button button href={'/org/new'} className="animated-svg">
            <span>{t('buttonText', { ns: 'pricing_plans' })}</span>
            <IconArrow className="ml-2" />
          </Button>
        </div>
      )}
      <div className="border-t border-b border-gray-200 py-3 text-sm  font-semibold">
        {planInfo?.primary.feature}
      </div>
      <div className={clsx('mt-2 flex flex-col', !tiny && 'gap-y-5')}>
        {planInfo?.items.map((item) => (
          <PricingBullet key={item.title} {...item} />
        ))}
      </div>
    </div>
  )
}
export default PricingPlan
